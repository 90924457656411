@import '/src/features/navigation/private/styles/global.scss';

.sl-navigation-actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    background: var(--modal);

    @include mediumDesktop() {
        flex-direction: row;
        background: transparent;
    }

    &__settings {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        border-top: 1px solid #{var(--border-color)};
        padding: 24px;

        @include mediumDesktop() {
            width: unset;
            border-top: unset;
            margin-left: 16px;
            padding: unset;
        }

        &.active {
            flex-direction: column;
            padding: 0;
            border-top: unset;
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
            z-index: 2;

            @include mediumDesktop() {
                flex-direction: row;
            }
        }

        &.hide {
            display: none;
            @include mediumDesktop() {
                display: flex;
            }
        }

        &__back-icon {
            width: 8px;
            height: 16px;
            margin-left: -8px;
            pointer-events: none;
            transform: rotate(180deg);
            opacity: 0.5;

            @include mediumDesktop() {
                display: none;
            }
        }

        &__go-back {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 16px 24px;
            font-size: 16px;
            line-height: 19px;
            font-weight: 600;
            background: var(--modal);
            border-bottom: 1px solid #{var(--border-color)};
            cursor: pointer;
            z-index: 2;
            color: var(--text-secondary);

            @include mediumDesktop() {
                display: none;
            }

            &__icon {
                height: 16px;
                width: 8px;
                margin-right: 8px;
            }
        }
    }

    &__links-wrapper {
        display: flex;
        border-top: 1px solid #{var(--border-color)};
        padding: 16px 24px;

        @include mediumDesktop() {
            border-top: unset;
            padding: unset;
        }

        @include media(mobile) {
            justify-content: center;
        }
    }

    .sl-action-btn {
        padding: 0 48px;
        white-space: nowrap;

        &--secondary {
            background-color: transparent;

            color: var(--action-button-text);

            &:hover {
                background: var(--action-button-hover);
            }
        }
        
        &:first-child {
            margin-right: 16px;

            @include mediumDesktop() {
                margin-right: 32px;
            }
        }
    }
    
    &__pro-button-wrapper {
        display: block;
        
        @include mediumDesktop() {
            display: none;
        }
    }
}
